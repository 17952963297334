
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BaiSearch from "@/layout/header/partials/BaiSearch.vue";
import QuickLinks from "@/layout/header/partials/QuickLinks.vue";
import mainSocket from "./../../main-socket";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import constant from '@/store/stateless/constant.js';

export default defineComponent({
  name: "topbar",
  components: {
    BaiSearch,
    QuickLinks
  },
  setup() {
    let notificationList = ref<Array<any>>([]);
    let paginationDetails = ref<any>();
    const SearchDomRef = ref();
    const dialogVisible = ref(false);
    const store = useStore();
    const router = useRouter();
    let userObj: any = {};
    let notificationCount = ref(0);
    
    const datetime = ref<any>(null);

    const { tea_break } = constant

    function teaBreak() {
        tea_break.open = !tea_break.open
        console.log('tea_break',tea_break)
    }
    
    
    onMounted(async() => {
      getNotificationCount();

      const currentUserId = user.value.id;
      mainSocket
        .channel("channel-" + currentUserId)
        .listen("UnReadNotification", (response) => {
          if (response.notification) {
            getNotificationCount();
          }
        });
    });

    const getNotificationCount = async() => {
      const params = {
      }
      const response = await useUpdateCustomerData({linkType: 'count-unread-notification', ...params })
      notificationCount.value = response.data.data.count
    };

    const user = computed(() => {
      userObj = LocalStorageServices.getUser();
      if (userObj == null) {
        userObj = {};
        return userObj;
      }
      return JSON.parse(userObj);
    });

    const getRecentNotification = async() => {
      const params = {
        per_page: 20,
        page: 1
      }
      const response = await useUpdateCustomerData({linkType: 'list-recent-notifications', ...params })
      notificationList.value = response.data.data.notifications.data
      delete response.data.data.notifications.data
      paginationDetails.value = {
        ...response.data.data.notifications
      }
    };

    // const handleNotificationAppend = (newData) => {
    //   notificationList.value.push(newData.notifications.data);
    //   delete newData.notifications.data
    //   paginationDetails.value = {
    //     ...newData.notifications
    //   }
    // }
    
    const signOut = async () => {
      const user = JSON.parse(window.localStorage.getItem('user') || '')
      const payload = { user_id: user.id }
      await store.dispatch(Actions.LOGOUT, payload);
      store.commit(Mutations.PURGE_AUTH);
    };

    const switchRole = async(e)=>{
        dialogVisible.value = false
        const data = await store.dispatch(Actions.SWITCH_ROLE,{type:'misc'})
        window.localStorage.setItem('uid', data.data.data.user.id);
        window.localStorage.setItem('user', JSON.stringify(data.data.data.user));
        // await store.dispatch(Actions.GET_ROUTE_LIST, { type: router.currentRoute.value.meta.product })
        await router.push({ name: 'dashboard' }).then(() => router.go(0))
      }

    return {
      datetime,
      SearchDomRef,
      getRecentNotification,
      notificationCount,
      notificationList,
      paginationDetails,
      getNotificationCount,
      user,
      signOut,
      switchRole,
      dialogVisible,
      teaBreak
    };
  }
});
