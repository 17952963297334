
import { defineComponent, onMounted } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
// import KTMenu from "@/layout/header/Menu.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import Switch from "@/layout/header/partials/Switch.vue";
import Products from "@/layout/header/partials/Products.vue";
import router from "@/router";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    Switch,
    Products,
    // KTMenu,
  },
  setup() {
    onMounted(() => {
      if (!window.localStorage.getItem("id_token")) {
        router.replace("/sign-in");
      }
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
