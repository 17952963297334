
import { defineComponent, ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import MasterService from "@/core/services/car/MasterService";
// import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "bai-search",
  //   components: {Multiselect},
  computed: {
    // getSearchUrl: (data) => {
    //     console.log('searchUrl.value',data.searchUrl)
    //     if(data.searchUrl && data.searchUrl == 'customer/details')
    //     {
    //         return '#/'+data.searchUrl+'/';
    //     }
    //     if(data.searchUrl && data.searchUrl == 'policy/creator/documents')
    //     {
    //         return '#/'+data.searchUrl+'/';
    //     }
    //     return "";
    // },
  },
  setup(props) {
    const store = useStore();
    const searchType = ref<number>(1);
    const HTMLSearch = ref();
    let searchList = ref<any>();
    let searchUrl = ref<string>("");
    let isBai = ref<boolean>(false);
    let showLoader = ref<boolean>(false);
    const route = useRoute()


    const getSearchResult = () => {
      const keyword = search.value;
      const params = {
        type: searchType.value,
        keyword: keyword,
      };
      const numberOnly = /^[0-9]+$/;
      if (
        !numberOnly.test(keyword) &&
        searchType.value == 1 &&
        keyword.length > 2
      ) {
        searchResult(params);
      } else if (
        searchType.value === 1 &&
        numberOnly.test(keyword) &&
        keyword.length > 3
      ) {
        searchResult(params);
      } else if (
        (searchType.value == 2 || searchType.value == 6) &&
        keyword.length > 3
      ) {
        searchResult(params);
      } else if (
        (searchType.value == 3 ||
          searchType.value == 4 ||
          searchType.value == 5) &&
        keyword.length > 0
      ) {
        searchResult(params);
      } else {
        searchList.value = [];
      }
    };

    const getSearchEnter = () => {
      const keyword = search.value;
      const params = {
        type: searchType.value,
        keyword: keyword,
      };
      searchResult(params);
      searchToDown();
    };
    onMounted(() => {
      // console.log({search:HTMLSearch});
    })
    const searchTypes = ref([
      { value: 5, label: "Car Details", isreadonlin: true },
      { value: 2, label: "Chassis No", isreadonlin: true },
      { value: 4, label: "Customer ID", isreadonlin: true },
      { value: 1, label: "General", isreadonlin: true },
      { value: 3, label: "Lead ID", isreadonlin: true },
      { value: 6, label: "Policy No", isreadonlin: true },
    ]);

    const searchResult = (params) => {
      showLoader.value = true;
      Promise.all([MasterService.getSearchResult(params)]).then((data) => {
        showLoader.value = false;
        const getSearch = store.getters.getSearch;
        searchList.value = getSearch.result;
        searchUrl.value = getSearch.url;
        isBai.value = getSearch.isBai;
      });
    };
    const getSearchUrl = (searchUrl, data) => {
      if (searchUrl == "policy/creator/documents" && !data?.policy_purchase) {
        return "/customer/details/" + data.customer_id;
      }
      const permissions = store.getters.getUserPermissions;
      alert("ss");
      console.log("searchUrl.value", searchUrl);
      if (searchUrl == "customer/details") {
        if (searchType.value == 3) {
          return "/" + searchUrl + "/" + data.id + "?lead_id=" + search.value;
        } else {
          return "/" + searchUrl + "/" + data.id;
        }
      }
      if (
        searchUrl == "policy/creator/documents" &&
        !permissions.is_marketing
      ) {
        if (data?.policy_purchase) {
          const policyId = data?.policy_purchase.id;
          if (policyId == undefined) {
            return "/customer/details/" + data.id;
          }
          if (searchUrl == "customer/details/") {
            return "/customer/details/" + data.customer_id;
          }
          return "/" + searchUrl + "/" + policyId;
        }
        if (
          !data?.policy_purchase &&
          (permissions.is_admin ||
            permissions.is_sales_agent ||
            permissions.is_team_leader ||
            permissions.is_manager ||
            permissions.is_accountant)
        ) {
          if (searchType.value == 3) {
            return "/customer/details/" + data.id + "?lead_id=" + search.value;
          } else {
            return "/customer/details/" + data.id;
          }
        }
      } else {
        if (searchType.value == 3) {
          return "/customer/details/" + data.id + "?lead_id=" + search.value;
        } else {
          return "/customer/details/" + data.customer_id;
        }
      }
      return "";
    };

    const goToNav = (item, url) => {
      const policyCreatorUrl = "policy/creator/documents";
      const customerUrl = `${route.meta.product}/customer/details`;
      if (url == policyCreatorUrl) {
        if(item.policy_id == null && item.invoice_status == 1) {
          return `/${customerUrl}/${item.main_customer_id}`;
        }
        if(item.policy_id == null && item.invoice_status == null) {
          return `/${customerUrl}/${item.main_customer_id}`;
        }
        return `/${policyCreatorUrl}/${item.policy_id}`;
      } else {
        if (searchType.value == 3) {
          return `/${customerUrl}/${item.main_customer_id}?lead_id=${item.car_lead_id}`;
        }
        return `/${customerUrl}/${item.main_customer_id}`;
      }
    };

    const searchToDown = () => {
      const inputs: Array<any> = Array.from(
        document.querySelectorAll(".search-result")
      );
      // inputs[0].focus();
    };

    const invoiceDisabled = (result) => {
      
      if (
        result.invoice_id ||
        isBai.value ||
        (userPermissions.value.is_underwritter || userPermissions.value.is_accountant || userPermissions.value.is_marketing)
      ) {
        if (
          (result.invoice_status != 2 ||
          result.invoice_status == null ||
          result.policy_id == null) && (userPermissions.value.is_underwritter || userPermissions.value.is_accountant || userPermissions.value.is_marketing)
        ) {
          if(userPermissions.value.is_accountant || userPermissions.value.is_marketing) {
            if(result?.quote_ref_no) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
      }
      return false;
    };

    const pressdown = (event) => {
      const inputs: Array<any> = Array.from(
        document.querySelectorAll(".search-result")
      );
      const index = inputs.indexOf(event.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    };
    const pressup = (event) => {
      const inputs: Array<any> = Array.from(
        document.querySelectorAll(".search-result")
      );
      const index = inputs.indexOf(event.target);

      if (index < inputs.length) {
        inputs[index - 1].focus();
      }
    };

    const search = ref("");

    const reset = () => {
      search.value = "";
      getSearchResult();
      toFirst()
    };

    const clear = () => {
      searchType.value = 1
      search.value = "";
      getSearchResult();
      toFirst()
    };

    const status = (e) => {
      
      if (e == null) {
        return "Payment pending";
      }
      const statuses = store.getters.getPolicyStatuses;
      console.log({statuses: statuses});
      const val = statuses.find((x: any) => x.value === e);
      return val.label;
    };
    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });
    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        const focusHere:any = inst?.refs?.focusHere
      focusHere.focus()
      }, 500);
    }
    return {
      clear,
      toFirst,
      HTMLSearch,
      userPermissions,
      isBai,
      invoiceDisabled,
      status,
      goToNav,
      getSearchEnter,
      reset,
      search,
      pressdown,
      pressup,
      searchToDown,
      searchTypes,
      searchType,
      getSearchResult,
      searchList,
      searchResult,
      searchUrl,
      getSearchUrl,
      showLoader,
    };
  },
});
