<template>
    <el-select
        v-model="product"
        class="m-2" 
        filterable
        placeholder="Select app" 
        size="large"
        @change="handleProductChange"
    >
        <el-option
            v-for="item in products"
            :key="item"
            :label="item.label"
            :value="item.value"
        />
    </el-select>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import router from '@/router';

const product = ref(router.currentRoute.value.meta.product)

const products = computed(() => JSON.parse(window.localStorage.getItem('products')))

function handleProductChange() {
    window.history.replaceState({...history.state}, '', `/#/${product.value}/dashboard`)
    window.location.reload()
}

</script>